.help_container {

  color: #120747;
  height: auto;
  min-height: 100vh;
  padding-bottom: 50px;
  background-color: #f4f1ff;
  background-image: none;

  .help_section {

    margin-top: 70px;
    padding: 60px;
    border-radius: 30px;
    background-color: #fbf9ff;
    background-image: none;
    background-size: auto;
    background-repeat: repeat;

  }

  h3 {
    font-size: 24px;
    line-height: 30px;
  }

  .div-block-9 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 60px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #110844;
  }

  .help-logo {
    margin-right: 18px;
    font-family: 'Fa solid 900', sans-serif;
    font-size: 48px;
    line-height: 48px;
  }

  .text-block-2 {
    font-size: 30px;
    line-height: 34px;
    font-family: Tiempostext, sans-serif;
  }

  .support_picture {
    width: 100%;
    height: 250px;
    border-radius: 30px;
    background-color: #f4f1ff;
    background-image: url('/assets/images/support_picture-01.svg');
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  .support_picture.como_funciona {
    height: 100%;
  }

  .div-block-4 {
    margin-top: 60px;
  }

  .div-block-4.grid_steps {
    margin-top: 15px;
  }

  .title {
    display: inline-block;
    padding: 2px 12px;
    border-radius: 14px;
    background-color: #ffdcdc;
    font-family: 'Varela Round', sans-serif;
    color: #e96363;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .h3 {
    margin-top: 5px;
    margin-bottom: 0px;
    font-family: Tiempostext, sans-serif;
    font-weight: 700;
  }

  .paragraph {
    margin-top: 10px;
    font-family: Montserrat, sans-serif;
    color: rgba(18, 7, 71, 0.8);
    font-size: 16px;
  }

  .div-block-5 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .button-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 30px;
    border-style: solid;
    border-width: 1px;
    border-color: #cec5ec;
    border-radius: 40px;
    color: #120747;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    background-color: white;
  }

  .button-2:hover {
    background-color: rgba(238, 230, 255, 0.66);
  }

  .button-2.mb {
    margin-bottom: 20px;
  }

  .button-2.mb:hover {
    background-color: #eee6ff;
  }

  .button-2.mb.p-relative {
    position: relative;
  }

  .button-2.mr {
    margin-right: 20px;
  }

  .text-block {
    margin-left: 15px;
    font-family: 'Fa solid 900', sans-serif;
  }

  .div-block-6 {
    margin-bottom: 50px;
  }

  a {
    font-family: Tiempostext, sans-serif;
  }

  .green-icon {
    position: absolute;
    left: auto;
    top: -12px;
    right: -21px;
    bottom: auto;
    padding: 2px 12px;
    border-radius: 17px;
    background-color: #a0eea2;
    font-family: 'Varela Round', sans-serif;
    color: #43a544;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .faq-block {
    padding: 15px 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #cec5ec;
    border-radius: 24px;
    background-color: white;
  }

  .faq-block.mb {
    margin-bottom: 20px;
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);
  }

  .faq-question {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }

  .arrow-right {
    font-family: 'Fa solid 900', sans-serif;
  }

  .faq-active {
    .arrow-right {
      -webkit-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90deg) skew(0, 0)
    }
  }

  .faq-answer {
    overflow: hidden;
    margin-top: 0px;
  }

  .paragraph-2 {
    margin-top: 12px;
    font-family: Montserrat, sans-serif;
    color: rgba(18, 7, 71, 0.8);
    font-size: 15px;
  }

  .faq-first {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }


  @media screen and (max-width: 479px) {

    .help_section {
      padding-right: 20px;
      padding-left: 20px;
    }

    .div-block-9 {
      margin-bottom: 30px;
    }

    .button-2 {
      padding-right: 20px;
      padding-left: 20px;
      font-size: 14px;
    }


  }

}
