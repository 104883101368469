.about-us-container {
  .content-container {
    width: 90%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 1rem;
  }


  .about-5th-grid-line {
    height: 10px;
    background-color: #e1e0ff;
  }
  .about-5th-grid {
    grid-column-gap: 32px;
    grid-row-gap: 0px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1024px) {
    .content-container {
      padding: 0;
    }

    .roadmap-container {
      margin-left: -44px;
    }

    .w-layout-grid {
      grid-row-gap: 16px;
      grid-column-gap: 16px;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      display: grid;
    }

    .about-5th-grid {
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      grid-template-rows: auto auto auto auto auto;
      grid-template-columns: auto 1fr;
    }

    .about-5th-grid-line {
      width: 8px;
      height: auto;
    }

    .text-mobile-lg {
      font-size: 2.50rem;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1400px) {
    .content-container {
      padding: 0;
    }
  }

}
